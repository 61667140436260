import { apiGet } from '../utils/http';

export async function getDepositCheckBox(params = {}) {
  const api = '/book/checkbox';
  return await apiGet(api, params);
}

export async function getCalendarHoliday(params = {}) {
  const api = '/book/calendarHoliday';
  return await apiGet(api, params);
}
